


































































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import axios from 'axios';
import { UserProfileInterface } from '../../interfaces';
import { apiBuilder } from '../../config';
import * as dayjs from 'dayjs';
import 'dayjs/locale/vi';

dayjs.locale('vi');

const auth = namespace('auth');

@Component
export default class Person extends Vue {
	@auth.State
	private token!: string;

	private userProfile: UserProfileInterface = {
		email: '',
		fullname: '',
		phonenumber: 0,
		username: '',
	};

	private userBalance: string | number = 0;

	private userAccredit: string | number = 0;

	private timeCreated = 'abc';

	private loading = true;

	private getUserProfile(): void {
		this.loading = true;

		const api = apiBuilder('profile/profileinfo.php');

		const param = {
			params: {
				token: this.token,
			},
		};

		axios
			.post(api, null, param)
			.then((res) => {
				// console.log(res.data)
				const error = res.data.error;

				if (error == '302') {
					sessionStorage.setItem('redirectPath', window.location.pathname);

					this.$router.replace({
						name: 'Login',
					});
				} else if (error != '301') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error! Plesae Reload',
					});
				}

				this.userProfile = res.data;
				this.userBalance = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(res.data.balance);
				this.userAccredit = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(res.data.accredited);
				this.timeCreated = dayjs.unix(Number(res.data.create_at)).format('DD/MM/YYYY HH:mm:ss');

				this.loading = false;
			})
			.catch((e) => {
				return this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				});
			});
	}

	private updateProfile(): void {
		this.loading = true;

		const api = apiBuilder('profile/profileinfochange.php');

		const param = {
			params: {
				email: this.userProfile.email,
				token: this.token,
				username: this.userProfile.username,
				fullname: this.userProfile.fullname,
				phonenumber: this.userProfile.phonenumber,
			},
		};

		axios
			.post(api, null, param)
			.then((res) => {
				// console.log(res.data)
				const error = res.data.error;

				if (error == '314') {
					sessionStorage.setItem('redirectPath', window.location.pathname);

					this.$store.dispatch('auth/removeUser');

					this.$router.replace({
						name: 'Login',
					});
				} else if (error == '312') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Số điện thoại sai định dạng!',
					});
				} else if (error == '313') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Email sai định dạng',
					});
				} else if (error != '311') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error! Plesae Reload',
					});
				}

				if (error == '311') {
					this.$swal({
						icon: 'success',
						title: 'Cập Nhật Thành Công !',
					});
				}

				this.loading = false;
			})
			.catch((e) => {
				return this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				});
			});
	}

	created() {
		this.getUserProfile();
	}
}
